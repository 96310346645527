/* global GLightbox */

$(document).ready(function() {
	GLightbox({
		selector: '.glightbox',
		touchNavigation: true,
		loop: false,
		autoplayVideos: true,
		closeButton: true,
		plyr: {
			// plyr CSS and JS URLS are required here otherwise "Inject videos api error"
			css: 'https://cdn.plyr.io/3.6.7/plyr.css', // default value
			js: 'https://cdn.plyr.io/3.6.7/plyr.polyfilled.js', // polyfilled version
			ratio: '16:9', // required otherwise error split undefined
			config: {
				autoplay: true,
				controls: ['play-large', 'progress', 'mute', 'volume', 'fullscreen'],
				clickToPlay: true,
				invertTime: false,
				vimeo: { quality: '1080p' }
			}
		}
	});
});