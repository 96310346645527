$(document).ready(function() {
	$('.contact_type-text-button').each(function(index, div) {
		$(this).click(function() {
			// remove class from all other buttons
			$('.contact_type-text-button').removeClass('contact_type-text-button-text--selected');
			// add class to currently clicked button
			$(this).addClass('contact_type-text-button-text--selected');
			// select corresponding radio field by simulating click
			var correspondingFormChoice = $('.contact-type_choice').get(index);
			if (correspondingFormChoice) {
				$(correspondingFormChoice).click();
			}
			else console.log('no correspondingFormChoice');
		});
	});
	$('.contact-type_choice').each(function(index, div) {
		// select corresponding contact text button
		$(this).click(function() {
			var correspondingContactText = $('.contact_type-text-button').get(index);
			if (correspondingContactText) {
				// remove class from all other buttons
				$('.contact_type-text-button').removeClass('contact_type-text-button-text--selected');
				// add class to currently clicked button
				$(correspondingContactText).addClass('contact_type-text-button-text--selected');
			}
			else console.log('no correspondingContactText');
		});
	});
});